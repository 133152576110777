import { Draft } from 'immer';
import { SERVICE_CONFIGURATION, UI_CONFIGURATION, ADD_LOCALE, UPDATE_SERVICE_DATA, SET_MUSTACHE_CLASS, SET_META, SET_STATUS, TOGGLE_FEATURED, SET_OVERVIEW, TOGGLE_TAB, UPDATE_TITLE_TRANSLATION, UPDATE_DESCRIPTION_TRANSLATION, ADD_NEW_FIELD, ADD_NEW_FIELD_GROUP, DELETE_FIELD, DELETE_FIELD_GROUP, TOGGLE_FIELD_REQUIRED, TOGGLE_FIELD_READONLY, UPDATE_FIELD_NAME, UPDATE_FIELD_TYPE, ADD_FIELD_OPTION, UPDATE_FIELD_OPTION, UPLOAD_ICON, SET_REDIRECT_URL, UPDATE_BUTTON_ONCLICK, UPDATE_TAB_TITLE_TRANSLATION, UPDATE_TAB_DESCRIPTION_TRANSLATION, ADD_NEW_TAB, UPDATE_FIELD_GROUP_TITLE_TRANSLATION, TOGGLE_SHOW_TAB_CARD_HEADER, UPDATE_FIELD_TITLE_TRANSLATION, UPDATE_FIELD_PLACEHOLDER_TRANSLATION, UPDATE_FIELD_DESCRIPTION_TRANSLATION, TOGGLE_MULTIPLE_INSTANCES_META, UPDATE_IFRAME_SRC, UPDATE_SERVICE_DATA_FIELD, UPDATE_SOURCE_HEADER, UPDATE_TARGET_HEADER, UPDATE_DEFAULT_MAPPINGS } from './actionTypes';
import { IntegrationAppFormState } from './state';

export const actionMap = {
  [SERVICE_CONFIGURATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft = { ...draft, ...payload };
    draft.modified = true;
  },
  [UI_CONFIGURATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft = { ...draft, ...payload };
    draft.modified = true;
  },
  [ADD_LOCALE]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.title_translations = {...draft.title_translations, ...payload.title_translations}
    draft.description_translations = {...draft.description_translations, ...payload.description_translations}
    draft.ui_layout = {...draft.ui_layout, ...payload.ui_layout}
    draft.modified = true;
  },
  [UPDATE_SERVICE_DATA]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.service_data = {
      create: payload.create,
      update: payload.update,
      delete: payload.delete,
    };
  },
  [SET_META]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.meta = {...draft.meta, ...payload.meta}
    draft.modified = true;
  },
  [SET_STATUS]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.status = payload.status
    draft.modified = true;
  },
  [SET_REDIRECT_URL]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.redirect_url = payload.redirect_url
    draft.modified = true;
  },
  [SET_MUSTACHE_CLASS]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft = { ...draft, ...payload };
    draft.modified = true;
  },
  [TOGGLE_FEATURED]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft = { ...draft, ...payload };
    draft.modified = true;
  },
  [SET_OVERVIEW]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.description_translations[payload.locale][payload.contentType] = payload.content
    draft.modified = true;
  },
  [TOGGLE_TAB]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.index][payload.tab]['enabled'] = payload.enabled
    draft.modified = true;
  },
  [UPDATE_TITLE_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.title_translations = { ...draft.title_translations, ...payload.title_translations };
    draft.modified = true;
  },
  [UPDATE_DESCRIPTION_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.description_translations = { ...draft.description_translations, ...payload.description_translations };
  },
  [ADD_NEW_FIELD_GROUP]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.index][payload.tab]['fieldGroups'].push({'fields':[], 'titleTranslations':{}})
    draft.modified = true;
  },
  [DELETE_FIELD_GROUP]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.index][payload.tab]['fieldGroups'].splice(payload.fieldGroupIndex,1)
    draft.modified = true;
  },
  [ADD_NEW_FIELD]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.index][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'].push({'name':'Field','required':false,'fieldType':'text','titleTranslations':{},'descriptionTranslations':{},'placeholderTranslations':{}})
    draft.modified = true;
  },
  [DELETE_FIELD]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'].splice(payload.fieldIndex,1)
    draft.modified = true;
  },
  [UPDATE_FIELD_TYPE]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['fieldType'] = payload.type
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['required'] = payload.required
    if (payload.type === 'readonly_field_mapping') {
      draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['direction'] = 'column';
    } else {
      delete draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['direction'];
      delete draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['rows'];
      delete draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['sourceHeader'];
      delete draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['targetHeader'];
    }
    draft.modified = true;
  },
  [UPDATE_FIELD_NAME]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['name'] = payload.name
    draft.modified = true;
  },
  [UPDATE_SOURCE_HEADER]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['sourceHeader'] = payload.sourceHeader;
    draft.modified = true;
  },
  [UPDATE_TARGET_HEADER]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['targetHeader'] = payload.targetHeader;
    draft.modified = true;
  },
  [UPDATE_DEFAULT_MAPPINGS]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['rows'] = JSON.parse(payload.rows);
    draft.modified = true;
  },
  [TOGGLE_FIELD_REQUIRED]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['required'] = payload.required
    draft.modified = true;
  },
  [TOGGLE_FIELD_READONLY]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['readonly'] = payload.readonly
    draft.modified = true;
  },
  // TODO(Xander): Rework select dropdowns so that they follow the rest of the formats - currently they store locale inside of them directly, rather than localized names separate from options
  // "optionTranslations": {
  //   "en": [{
  //     "key": "data_object_option1",
  //     "value": "data_object_option1"
  //   }, {
  //     "key": "data_object_option2",
  //     "value": "data_object_option2"
  //   }, {
  //     "key": "data_object_option3",
  //     "value": "data_object_option3"
  //   }]
  // },
  // TO
  // "options": [
  //   "option1", "option2", "option3"
  // ]
  // "optionTranslations": {
  //   "en": {
  //     "option1": "Option 1",
  //     "option2": "Option 2",
  //     "option3": "Option 3"
  //   }
  // }
  [ADD_FIELD_OPTION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.modified = true;
  },
  [UPDATE_FIELD_OPTION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.modified = true;
  },
  [UPLOAD_ICON]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.icon = payload.icon;
    draft.modified = true;
  },
  [UPDATE_BUTTON_ONCLICK]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['onClick'] = payload.onClick;
    draft.modified = true;
  },
  [UPDATE_TAB_TITLE_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    if (!draft.ui_layout['tabs']?.[payload.tabIndex]?.[payload.tab]?.['titleTranslations']?.[payload.locale]) {
      draft.ui_layout['tabs'][payload.tabIndex][payload.tab] = {
        ...draft.ui_layout['tabs'][payload.tabIndex][payload.tab],
        titleTranslations: {
          [payload.locale]: {}
        }
      };
    }
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['titleTranslations'][payload.locale][payload.key] = payload.value;
    draft.modified = true;
  },
  [UPDATE_TAB_DESCRIPTION_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    if (!draft.ui_layout['tabs']?.[payload.tabIndex]?.[payload.tab]?.['descriptionTranslations']?.[payload.locale]) {
      draft.ui_layout['tabs'][payload.tabIndex][payload.tab] = {
        ...draft.ui_layout['tabs'][payload.tabIndex][payload.tab],
        descriptionTranslations: {
          [payload.locale]: {}
        }
      };
    }
    draft.ui_layout['tabs'][payload.tabIndex][payload.tab]['descriptionTranslations'][payload.locale][payload.key] = payload.value;
    draft.modified = true;
  },
  [ADD_NEW_TAB]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'].push({
      [payload.tabName]: {
        enabled: false,
        fieldGroups: [],
        titleTranslations: { en: { tabName: payload.tabName } },
        descriptionTranslations: { en: { description: payload.tabName } }
      }
    });
    draft.modified = true;
  },
  [UPDATE_FIELD_GROUP_TITLE_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    if (!draft.ui_layout['tabs']?.[payload.tabIndex]?.[payload.tabName]?.['fieldGroups']?.[payload.fieldGroupIndex]?.['titleTranslations']) {
      draft.ui_layout['tabs'][payload.tabIndex][payload.tabName]['fieldGroups'][payload.fieldGroupIndex]['titleTranslations'] = {};
    }
    draft.ui_layout['tabs'][payload.tabIndex][payload.tabName]['fieldGroups'][payload.fieldGroupIndex]['titleTranslations'][payload.locale] = payload.value;
    draft.modified = true;
  },
  [TOGGLE_SHOW_TAB_CARD_HEADER]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.index][payload.tabName]['showHeader'] = payload.showHeader;
    draft.modified = true;
  },
  [UPDATE_FIELD_TITLE_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tabName]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['titleTranslations'][payload.locale] = payload.value;
    draft.modified = true;
  },
  [UPDATE_FIELD_DESCRIPTION_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tabName]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['descriptionTranslations'][payload.locale] = payload.value;
    draft.modified = true;
  },
  [UPDATE_FIELD_PLACEHOLDER_TRANSLATION]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tabName]['fieldGroups'][payload.fieldGroupIndex]['fields'][payload.fieldIndex]['placeholderTranslations'][payload.locale] = payload.value;
    draft.modified = true;
  },
  [UPDATE_SERVICE_DATA_FIELD]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.service_data[payload.key] = payload.isJSON ? JSON.parse(payload.value) : payload.value;
    draft.modified = true;
  },
  [TOGGLE_MULTIPLE_INSTANCES_META]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.meta['multiple'] = payload;
    draft.modified = true;
  },
  [UPDATE_IFRAME_SRC]: (draft: Draft<IntegrationAppFormState>, payload: any) => {
    draft.ui_layout['tabs'][payload.tabIndex][payload.tabName]['iframe'] = payload.value;
    draft.modified = true;
  }
};
